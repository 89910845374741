<template lang="html">

  <div class="container" v-if="!mobile">

    <div class="card-body">
      <router-link class="navbar-brand" to="/executiveSearchEng">
        <div class="imgContainer">
          <h3>Executive Search</h3>
          <img src="@/assets/usluge/ExecutiveSearch.jpg" alt=""  >
        </div>
         </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/outsourcehrEng">
        <div class="imgContainer">
          <h3>HR Function Outsource</h3>
          <img src="@/assets/usluge/OutsourceHRfunkcije.jpg" alt=""  >
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/uvodjenjeHRfunkcijeEng">
        <div class="imgContainer">
          <h3>HR Function</h3>
          <img src="@/assets/usluge/multitasking.jpg" alt=""  >
        </div> </router-link>
    </div>

    <div class="card-body">
      <router-link class="navbar-brand" to="/businesscoachingEng">
        <div class="imgContainer">
          <h3>Business Coaching</h3>
          <img src="@/assets/usluge/BusinessCoaching.jpg" alt=""  >
        </div>
      </router-link>
    </div>

    <div class="card-body">
      <router-link class="navbar-brand" to="/analizaorganizacionogmodelaEng">
        <div class="imgContainer">
          <h3>Company´s Organizational Model Analysis</h3>
          <img src="@/assets/usluge/Analizaorganizacionogmodela.jpg" alt=""  >
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/performanceMenagementEng">
        <div class="imgContainer">
          <h3>Performance Management</h3>
          <img src="@/assets/usluge/PerformanceManagement.png" alt=""  >
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/talentMenagementEng">
        <div class="imgContainer">
          <h3>Talent Management</h3>
          <img src="@/assets/usluge/different.jpg" alt=""  >
        </div>
         </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/hrProfessionalProgramEng">
        <div class="imgContainer">
          <h3>HR Professional Program</h3>
          <img src="@/assets/usluge/HRProfessionalprogram.png" alt=""  >
        </div>
       </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/assessmentProcenaTimovaEng">
        <div class="imgContainer">
           <h3>Assessment / Team Assessment</h3>
          <img src="@/assets/usluge/Assessment.jpg" alt=""  >
        </div>
       </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/procena360Eng">
        <div class="imgContainer">
          <h3>360˚ Assessment</h3>
          <img src="@/assets/usluge/Procena360.jpg" alt=""  >
        </div>
       </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brand" to="/istrazivanjeStavovaZaposlenihEng">
        <div class="imgContainer">
          <h3>Employee Attitudes Survey</h3>
          <img src="@/assets/usluge/Istrazivanjestavovazaposlenih.jpg" alt=""  >
        </div>
       </router-link>
    </div>
  </div>



  <div class="container" v-if="mobile">
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/executiveSearchEng">
        <div class="imgContainer">
          <h3>Executive Search</h3>
          <img class="mobileImg" src="@/assets/usluge/ExecutiveSearch.jpg" alt=""  >
        </div>

         </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/outsourcehrEng">
        <div class="imgContainer">
          <h3>HR Function Outsource</h3>
          <img class="mobileImg" src="@/assets/usluge/OutsourceHRfunkcije.jpg" alt=""  >
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/uvodjenjeHRfunkcijeEng">
        <div class="imgContainer">
          <h3>HR Function</h3>
          <img class="mobileImg" src="@/assets/usluge/multitasking.jpg" alt=""  >
        </div> </router-link>
    </div>

    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/businesscoachingEng">
        <div class="imgContainer">
          <h3>Business Coaching</h3>
          <img class="mobileImg" src="@/assets/usluge/BusinessCoaching.jpg" alt=""  >
        </div>
      </router-link>
    </div>

    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/analizaorganizacionogmodelaEng">
        <div class="imgContainer">
          <h3>Company's Organizational Model Analysis</h3>
          <img class="mobileImg" src="@/assets/usluge/Analizaorganizacionogmodela.jpg" alt=""  >
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/performanceMenagementEng">
        <div class="imgContainer">
          <h3>Performance Management</h3>
          <img class="mobileImg" src="@/assets/usluge/PerformanceManagement.png" alt=""  >
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/talentMenagementEng">
        <div class="imgContainer">
          <h3>Talent Management</h3>
          <img class="mobileImg" src="@/assets/usluge/different.jpg" alt=""  >
        </div>
         </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/hrProfessionalProgramEng">
        <div class="imgContainer">
          <h3>HR Professional Program</h3>
          <img class="mobileImg" src="@/assets/usluge/HRProfessionalprogram.png" alt=""  >
        </div>
       </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/assessmentProcenaTimovaEng">
        <div class="imgContainer">
          <h3>Assessment / Team Assessment</h3>
          <img class="mobileImg" src="@/assets/usluge/Assessment.jpg" alt=""  >
        </div>
       </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/procena360Eng">
        <div class="imgContainer">
          <h3>360˚ Assessment</h3>
          <img class="mobileImg" src="@/assets/usluge/Procena360.jpg" alt=""  >
        </div>
       </router-link>
    </div>
    <div class="card-body">
      <router-link class="navbar-brandMobile" to="/istrazivanjeStavovaZaposlenihEng">
        <div class="imgContainer">
          <h3>Employee Attitudes Survey</h3>
          <img class="mobileImg" src="@/assets/usluge/Istrazivanjestavovazaposlenih.jpg" alt=""  >
        </div>
       </router-link>
    </div>
  </div>

</template>

<script>
export default {
  name: 'KSUslugeEng',
  components: {

  },
  methods:{

  },
  data(){
    return{
      mobile:false
    }
  },
  created(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile=true;
    } else {
      this.mobile=false;
    }
  },
  beforeCreate: function() {
        document.body.className = 'uslugePanel';
  }
}

</script>


<style lang="css" scoped>
h3{
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.navbar-brand{
  width: 300px;
  height: 300px;
}
.navbar-brandMobile{
  width: 200px;
  height: 200px;
}
img {
  width:500px;
  height: 250px;
}
.mobileImg{
  width:300px;
  height: 150px;
}
.usluga{

  border-radius: 15px;
  border-color: red;
}

.container {
  position: absolute;

}
.containerMobile {
  position: absolute;

}
.imgContainer {
  position: relative;
  text-align: center;
  color: white;
}
img:hover{
  border-style: solid;
  border-color: black;
  border-width:thick;
  border-radius: 15px;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



#carouselExampleIndicators{

  background-color: #000000;
  margin: auto;
}



</style>
